import React from 'react'
import FranciscoCandioti from '../multimedia/Francisco Candioti.jpg'

function Hola() {
    return (
        <div id="hola">
            <div className='seccion'>
                <h2>¡Hola, qué bueno que estés aquí!</h2>
                <div className='descripcion'>
                    <div className='texto'>
                        <p>Este sitio está destinado a productores y técnicos lecheros. Aquí encontrarás publicaciones y herramientas simples y originales para la gestión de establecimientos lecheros sin importar su tamaño. La mayoría de los recursos son gratuitos.</p>
                        <p>Espero que la lectura de las publicaciones y el uso de las aplicaciones te sirvan mucho en tu formación y en tu trabajo. Para todas las aplicaciones pueden hacerse cursos de capacitación online. No dudes en contactarme para solicitar más información o para enviarme tus comentarios. Registrate para recibir novedades y actualizaciones.</p>
                    </div>
                    <div id='francisco'>
                        <div className='francisco'>
                            <p><b><a href='https://www.linkedin.com/in/francisco-candioti-0b167834/' target="_blank" rel="noopener noreferrer">Ing. Agr. EPL Francisco Candioti</a></b></p>
                            <p><a href='mailto:panchocandioti@gmail.com'>panchocandioti@gmail.com</a></p>
                        </div>
                        <div className='francisco'>
                            <img src={FranciscoCandioti} alt="Francisco Candioti"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hola