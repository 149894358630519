import React from 'react'
import botonHome from '../multimedia/home-button-icon-png-6.jpg'

function BotonHome() {
    return (
        <div>
            <button id="botonhome" onClick={() => window.location.href = '#App'}><img src={botonHome} alt="Boton Home" title="Volver al inicio"></img></button>
        </div>
    )
}

export default BotonHome