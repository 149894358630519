import React from 'react';
import LogoSalto from '../multimedia/Logo Salto Agro.png';
import LogoManfrey from '../multimedia/logo-Manfrey.jpg';

function Patrocinadores() {
  return (
    <div>
    <div className='seccion'>
          <h2>Patrocinadores</h2>
          <div className='logos'>
            <a href='https://saltoagro.ar' target='_blank' rel='noopener noreferrer'><img src={LogoSalto} alt='LogoSalto' className='logo'></img></a>
            <a href='https://manfrey.com.ar' target='_blank' rel='noopener noreferrer'><img src={LogoManfrey} alt='LogoManfrey' className='logo'></img></a>
          </div>
        </div>
    </div>
  )
}

export default Patrocinadores