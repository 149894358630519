import React from 'react'
import calculadora from '../multimedia/MiCalculadoraLechera.jpg';

function MiCalculadoraLechera() {
    return (
        <div id="micalculadoralechera">
            <div className='seccion'>
                <h2><i>Mi</i> CALCULADORA LECHERA</h2>
                <img src={calculadora} className='diapo' alt='crecimiento_rodeo'></img>
                <div className='descripcion'>
                    <h3>Descripción:</h3>
                    <div className='texto'>
                        <p>Aplicación web desarrollada en el marco del convenio público-privado de cooperación científico-técnica entre
                            la <a href='https://unl.edu.ar/' target="_blank" rel="noopener noreferrer">Universidad Nacional del Litoral</a>, a través de su <a href='https://fca.unl.edu.ar/'
                            target="_blank" rel="noopener noreferrer">Facultad de Ciencias Agrarias</a> y la empresa <a a href='https://saltoagro.ar/'
                            target="_blank" rel="noopener noreferrer">Salto Agro S.S.</a></p>
                        <p>Permite calcular resultados económicos y costo de producción de un sistema de producción de leche en minutos.</p>
                        <p>Los datos de entrada requeridos son acotados en su cantidad y relativamente simples de obtener para el usuario.</p>
                        <p>Compara escenarios alternativos con la situación original. Emite reportes para descargar en formato PDF.</p>
                        <p>Está dirigida a un rango muy amplio de usuarios relacionados con la producción lechera (profesionales, productores, estudiantes).</p>
                    </div>
                </div>
                <div className='generalidades'>
                    <ul>
                        <li><b>Requerimientos:</b> navegador web</li>
                        <li><b>Sistema operativo:</b> cualquiera</li>
                        <li><b>Dispositivos:</b> cualquiera</li>
                        <li><b>Tipo de licencia:</b> gratuita</li>
                        <li><b>Videos tutoriales:</b> disponibles</li>
                        <li><b>Guía del usuario:</b> no disponible</li>
                        <li><b>Ayudas rápidas:</b> disponibles</li>
                        <li><b>Capacitación inicial:</b> recomendada</li>
                        <li><b>Complejidad:</b> baja/intermedia</li>
                    </ul>
                </div>
                <br></br>
                <div>
                    <h3>Presentación de la aplicación:</h3>
                </div>
                <div className='texto'>
                    <p>Videograbación del evento virtual de presentación de la aplicación realizado el 25 de julio de 2024.</p>
                </div>
                <div className="videos">
                    <iframe className='video' width="336" height="189" src="https://www.youtube.com/embed/CVv2T_k-mvs?si=YwKzd30wSsP1DCxr" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
                <br></br>
                <div>
                    <h3>Tutoriales:</h3>
                </div>
                <div className='texto'>
                    <p>Para aprovechar al máximo la aplicación, es recomendable que veas los siguientes videos tutoriales. </p>
                </div>
                <div className="videos">
                    <iframe className='video' width="336" height="189" src="https://www.youtube.com/embed/3bAnVppIhuE?si=CtG5pUzoz0hCEdKO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    <iframe className='video' width="336" height="189" src="https://www.youtube.com/embed/iusvmRrPO7M?si=RxZ9VO0T2fJREocr" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
                <hr></hr>
                <a href='https://milecheria.ar/micalculadoralechera/' target="_blank" rel="noopener noreferrer">Ir a la aplicación</a>
                <br></br>
            </div>
        </div>
    )
}

export default MiCalculadoraLechera