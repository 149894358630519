import React from 'react'
import parteI from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_I.pdf'
import parteII from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_II.pdf'
import parteIII from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_III.pdf'
import parteIV from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_IV.pdf'
import parteV from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_V.pdf'
import parteVI from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_VI.pdf'
import parteVII from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_VII.pdf'
import parteVIII from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_VIII.pdf'
import parteIX from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_IX.pdf'
import parteX from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_X.pdf'
import parteXI from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_XI.pdf'
import parteXII from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_XII.pdf'
import parteXIII from '../publicaciones/CRECIMIENTO_DEL_RODEO_LECHERO_-_Parte_XIII.pdf'

function IndicePublicaciones() {
    return (
        <div className='seccion'>
            <h2>PUBLICACIONES</h2>
            <div className='descripcion'>
                <h3>Serie - Crecimiento del rodeo lechero</h3>
                <ul className='lista'>
                    <li>Crecimiento del rodeo lechero - PARTE I - ¿De qué estamos hablando? <a href={parteI} download={parteI}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE II - ¿Qué pasa con el rodeo nacional? <a href={parteII} download={parteII}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE III - ¿Cómo crecer? <a href={parteIII} download={parteIII}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE IV - Eficiencia reproductiva <a href={parteIV} download={parteIV}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE V - Bajas de hembras jóvenes <a href={parteV} download={parteV}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE VI - Aceleración de la recría <a href={parteVI} download={parteVI}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE VII - Duración de las vacas <a href={parteVII} download={parteVII}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE VIII - Calcule cuánto duran las vacas <a href={parteVIII} download={parteVIII}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE IX - Costo de rechazo y muerte de vacas <a href={parteIX} download={parteIX}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE X - Duración de vacas y sistema productivo <a href={parteX} download={parteX}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE XI - Nueva aplicación web <a href={parteXI} download={parteXI}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE XII - Duplicar vacas en 5 años <a href={parteXII} download={parteXII}>(PDF)</a></li>
                    <li>Crecimiento del rodeo lechero - PARTE XIII - ¡Show me the money! <a href={parteXIII} download={parteXIII}>(PDF)</a></li>
                </ul>
                <h3>Proyecto <a href='https://www.fontagro.org/new/proyectos/lecheria-sostenible/es' target="_blank" rel="noopener noreferrer">LACTIS</a></h3>
                <ul className='lista'>
                    <li>Milk production systems in Latin America and the Caribbean: Biophysical, socio-economic, and environmental performance <a href='mailto:panchocandioti@gmail.com?Subject=Mensaje%20desde%20MiLecheria.ar&body=Quiero%20una%20copia%20del%20trabajo%20científico.%20Gracias!' target="_blank" rel="noopener noreferrer">(solicitar copia por e-mail)</a></li>
                    <li>OLE! Dairy model - Description and evaluation of the biophysical components of a whole farm simulation model for pastoral-based dairy systems <a href="https://doi.org/10.31285/AGRO.28.1202" target="_blank" rel="noopener noreferrer">(link)</a></li>
                </ul>
                <h3>Artículos de divulgación</h3>
                <ul className='lista'>
                    <li>Apuntes sobre el crecimiento de la Lechería de Argentina (10/05/2024) <a href="https://www.ocla.org.ar/noticias/29988968-apuntes-sobre-el-crecimiento-de-la-lecheria-de-argentina" target="_blank" rel="noopener noreferrer">(link)</a></li>
                </ul>
            </div>
        </div>
    )
}

export default IndicePublicaciones