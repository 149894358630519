import React from 'react';
import Hola from './componentes/Hola';
import ImagotipoBLANCO from './multimedia/Imagotipo BLANCO.png';
import './App.css';
import Patrocinadores from './componentes/Patrocinadores';
import Ole from './componentes/Ole';
import CrecimientoRodeo from './componentes/CrecimientoRodeo';
import MonitorLechero from './componentes/MonitorLechero';
import IndiceAplicaciones from './componentes/IndiceAplicaciones';
import IndicePublicaciones from './componentes/IndicePublicaciones';
import BotonHome from './componentes/BotonHome';
import Evento from './componentes/Evento';
import MiCalculadoraLechera from './componentes/MiCalculadoraLechera';

function App() {
  return (
    <div className="App" id="App">
      <header className="App-header">
        <img src={ImagotipoBLANCO} className="App-logo" alt="logo" />
      </header>
      <nav id="barra-navegacion">
        <button className="botonNav" onClick={() => window.location.href = '#publicaciones'}>PUBLICACIONES</button>
        <button className="botonNav" onClick={() => window.location.href = "#aplicaciones"}>APLICACIONES</button>
        <a href='https://forms.gle/Q12JUazDxuxbRSP7A' target="_blank" rel="noopener noreferrer"><button className="botonNav" title="Registrarse para recibir novedades">REGISTRARSE</button></a>
      </nav>
      <div>
        <div>
          <Evento />
          <Hola />
          <Patrocinadores />
        </div>
        <div id="publicaciones" className='tituloseccion'>
          <IndicePublicaciones />
        </div>
        <div id="aplicaciones" className='tituloseccion'>
          <IndiceAplicaciones />
          <Ole />
          <CrecimientoRodeo />
          <MiCalculadoraLechera />
          <MonitorLechero />
          <BotonHome />
        </div>
      </div>
    </div>
  );
}

export default App;
