import React from 'react'

function IndiceAplicaciones() {
  return (
    <div className='seccion'>
      <h2>APLICACIONES</h2>
      <div className='aplicaciones'>
        <button className="botonApp" onClick={() => window.location.href = '#ole'}>Organizador Lechero - OLE!</button>
        <button className="botonApp" onClick={() => window.location.href = "#crecimientorodeo"}>Crecimiento del rodeo lechero</button>
        <button className="botonApp" onClick={() => window.location.href = "#micalculadoralechera"}><i>Mi</i> Calculadora Lechera</button>
        <button className="botonApp" onClick={() => window.location.href = "#monitorlechero"}>Monitor Lechero - ML1</button>
      </div>
    </div>
  )
}

export default IndiceAplicaciones