import React from 'react'
import ML from '../multimedia/ML.png';

function MonitorLechero() {
    return (
        <div id="monitorlechero">
            <div className='seccion'>
                <h2>MONITOR LECHERO</h2>
                <img src={ML} className='diapo' alt='ML'></img>
                <div className='descripcion'>
                    <h3>Descripción:</h3>
                    <div className='texto'>
                        <p>Sistema de información para aplicar a las etapas de control de ejecución y evaluación de establecimientos lecheros.</p>
                        <p>Ideal para grupos de productores (cooperativas, Cambio Rural, CREA, grupos de asesoramiento y otros).</p>
                        <p>Emite un completo set de informes con indicadores físicos y económicos (en moneda local y en dólares) con enfoque mensual, anual y evolutivo.</p>
                        <p>Facilita la comparación horizontal (con otros establecimientos) y vertical (entre ejercicios del mismo establecimiento)</p>
                        <p>Todos los meses cierra ejercicio para los últimos 12 períodos mensuales, lo que permite iniciar en cualquier momento y manejar la/s fecha/s de corte según las necesidades particulares de cada empresa o grupo de empresas.</p>
                    </div>
                </div>
                <div className='generalidades'>
                    <ul>
                        <li><b>Requerimientos:</b> Excel 2010 o superior</li>
                        <li><b>Sistema operativo:</b> Windows</li>
                        <li><b>Dispositivos:</b> ordenador</li>
                        <li><b>Tipo de licencia:</b> de pago</li>
                        <li><b>Videos tutoriales:</b> no disponibles</li>
                        <li><b>Guía del usuario:</b> no disponible</li>
                        <li><b>Ayudas rápidas:</b> disponibles</li>
                        <li><b>Capacitación inicial:</b> requerida</li>
                        <li><b>Complejidad:</b> intermedia/alta</li>
                    </ul>
                </div>
                <hr></hr>
                <a href='mailto:panchocandioti@gmail.com?Subject=Mensaje%20desde%20MiLecheria.ar&body=Quiero%20más%20información%20sobre%20el%20Monitor%20Lechero.%20Gracias!' target="_blank" rel="noopener noreferrer">Requerir info por e-mail</a>
                <br></br>
            </div>
        </div>
    )
}

export default MonitorLechero;