import React from 'react'
import { useState } from 'react'
import Flyer from '../multimedia/Evento-CrecimientoRodeoLechero.png'

function Evento() {

    //El evento está desactivado con "isVisible" en valor false. Cambiar a true para activar.
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
      setIsVisible(false);
    };

  return (
    isVisible && (
      <div id="flyer-overlay">
        <div id="flyer-container">
          <img src={Flyer} alt="Flyer del Evento" id="flyer" />
          <button id="close-button" onClick={handleClose}>Cerrar</button>
          <a id='inscripcion' href='https://forms.gle/W5WpavajpBEUt3FHA' target="_blank" rel="noopener noreferrer">Inscripción aquí</a>
        </div>
      </div>
    )
  );
}

export default Evento